* {
  box-sizing: border-box;
}

.App {

}

.page {
  text-align: center;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  min-height: 100vh;
  width: 100vw;
  transition: 0.3s;
}

.page.modalOpen {
  filter: blur(4px);
}

.nav {
  background: #225440;
  /*background: #54b37f;*/
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 92px;
  padding: 0.5em 1em;
  color: white;
}

.nav > img {
  /*width: 150px;*/
  width: 220px;
  height: auto;
}

.nav a {
  text-decoration: none;
  font-size: 0.85em;
  color: black;
  color: white;

  &:hover {
    opacity: 0.7;
  }
}

.row {
  padding: 1em 2em;
  width: 100%;
  max-width: 999px;
  margin: auto;
}